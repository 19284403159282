'use client';

import React from 'react';
import Loader from './loader';
import { cn } from '@/lib/utils';

const FullPageLoader = () => {
  React.useEffect(() => {
    document.body.classList.add('loading');
    return () => {
      document.body.classList.remove('loading');
    };
  // Note: Empty dependency array ensures the effect runs
  // only once on mount and once on unmount
  }, []);

  return (<>
    <div className={cn(
      'mgsm-full-page-loader',
      'fixed inset-0 z-50 flex items-center justify-center w-full h-full',
      'bg-[rgba(255,255,255,.5)] dark:bg-[rgba(0,0,0,.5)] bg-opacity-50 cursor-wait'
    )}>
      <Loader />
    </div>
  </>);
};

export default FullPageLoader;
