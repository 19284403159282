import React from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { cmsImageUrl, cn, isProductVariant } from '@/lib/utils';
import { PopulatedProduct, PopulatedProductVariant } from '@repo/api-types/generated';

const ProductCardImage = ({ product, ...props }: {
  product: PopulatedProduct | PopulatedProductVariant;
} & React.HTMLAttributes<HTMLAnchorElement>) => {
  const productPageURL = `/store/${product.slug}`;
  const productImage = product.images?.length ? product.images[0] : null;
  if (!productImage) return null;
  return (
    <Link className='mgsm-product-card-image-link w-full min-h-[280px] sm:h-[350px] overflow-hidden relative bg-primary/10' href={productPageURL} {...props}>
      <Image
        src={cmsImageUrl(productImage.media.data.attributes.formats?.large?.url ?? productImage.media.data.attributes.url)}
        alt={product.name}
        height={productImage.media.data.attributes.formats?.large?.height ?? productImage.media.data.attributes.height}
        width={productImage.media.data.attributes.formats?.large?.width ?? productImage.media.data.attributes.width}
        className={cn(
          'mgsm-product-card-image',
          'w-full sm:w-auto h-full object-cover object-center mx-auto',
          'duration-300 ease-in-out transition-all',
          typeof productImage !== 'string' && productImage.hoverVariant?.data
            ? 'opacity-100 group-hover:opacity-0'
            : 'group-hover:scale-110',
          typeof productImage !== 'string' && productImage.className
            ? productImage.className
            : null,
          isProductVariant(product) && product.imageFilter ? product.imageFilter : null,
        )}
        onDragStart={(e) => e.preventDefault()}
      />
      {productImage.hoverVariant?.data && (
        <Image
          src={cmsImageUrl(productImage.hoverVariant.data.attributes.formats?.large?.url ?? productImage.hoverVariant.data.attributes.url)}
          alt={`${product.name} Active Variant`}
          height={productImage.hoverVariant.data.attributes.formats?.large?.height ?? productImage.hoverVariant.data.attributes.height}
          width={productImage.hoverVariant.data.attributes.formats?.large?.width ?? productImage.hoverVariant.data.attributes.width}
          className={cn(
            'mgsm-product-card-image-hover',
            'absolute inset-0 object-cover w-full sm:w-auto h-full mx-auto',
            'opacity-0 group-hover:opacity-100 duration-300 ease-in-out transition-all',
            productImage.hoverVariantClassName,
          )}
          onDragStart={(e) => e.preventDefault()}
        />
      )}
    </Link>
  );
};

export default ProductCardImage;
