'use client';

import React from 'react';

import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { cn, displayCurrency, getApplicationFee } from '@/lib/utils';
import LinkButton, { LinkButtonProps } from '../buttons/link-button';

import {
  HybridTooltip,
  HybridTooltipContent,
  HybridTooltipTrigger,
  HybridTooltipProvider,
} from '@/components/ui/hybrid-tooltip';

import { motion } from 'framer-motion';
import { HelpCircleIcon } from 'lucide-react';
import ProductTooltipDisplay from './product-tooltip-display';
import ProductCardImage from './product-card-image';
import { PopulatedProduct, PopulatedProductVariant, SiteConfiguration } from '@repo/api-types/generated';

const storeButtonClassName = cn(
  'w-full text-white font-bold duration-300 ease-in-out transition-all',
  'border-[2px] border-transparent group-hover:border-primary/25 dark:group-hover:border-primary',
  'bg-transparent group-hover:bg-primary/10 dark:group-hover:bg-primary/50',
  'text-black dark:text-white',
);

const ProductCardFlare = ({ type, text, className }: {
  type: 'ribbons' | 'banners',
  text: string;
  className?: string;
}) => {
  const hideClass = 'group-hover:opacity-0 duration-300 ease-in-out';
  if (type === 'ribbons') {
    return (<div className={cn(
      'absolute transform rotate-45 bg-primary text-center text-white font-semibold py-1 right-[-37px] top-[30px] w-[170px] z-10',
      hideClass,
      className,
    )}>
      {text}
    </div>);
  }

  return (<div className={cn(
    'absolute top-0 left-0 z-10 bg-primary text-white font-bold px-2 py-1 rounded-t-xl w-full text-center',
    className,
  )}>
    {text}
  </div>);
};

const ProductCard = ({ className, cardClassName, product, siteConfiguration, linkButtonProps }: {
  product: PopulatedProduct | PopulatedProductVariant;
  className?: string;
  cardClassName?: string;
  linkButtonProps?: Omit<LinkButtonProps, 'href'>;
  siteConfiguration: SiteConfiguration;
}) => {
  const productData = product;
  const productPageURL = `/store/${productData.slug}`;
  const actionText = 'Purchase';
  const productImage = productData.images?.length ? productData.images[0] : null;
  const hasInfoDisplay = !!(productData.benefitsDisplay?.length || productData.tooltipDisplay?.length);
  const hasMetaDisplay = !!(productData.meta?.bestSeller || productData.meta?.bestValue || productData.meta?.new);
  const cheapestVariant = product.variants?.reduce((acc, variant) => {
    if (!acc || variant.priceCents < acc.priceCents) {
      return variant;
    }
    return acc;
  }, productData);
  const inclusiveApplicationFee = siteConfiguration.attributes.billing?.mgsmProcessingFeeInclusive
    ? getApplicationFee(productData.priceCents)
    : 0;
  const displayProductPrice = product.variants && product.variants?.length > 1 && cheapestVariant
    ? `From ${displayCurrency(cheapestVariant.priceCents + inclusiveApplicationFee)}`
    : displayCurrency(productData.priceCents + inclusiveApplicationFee);

  return (<motion.div
    className={cn(
      'mgsm-product-card',
      'flex grow w-full self-stretch shrink-0 max-w-[350px] group overflow-hidden',
      className
    )}
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ duration: 0.5 }}
  >
    <Card className={cn(
      'relative z-[2] w-full flex flex-col bg-background',
      'transition-all duration-300 ease-in-out',
      // 'group-hover:shadow-lg group-hover:dark:shadow-primary/15',
      'rounded-xl lg:rounded-2xl overflow-hidden',
      !productImage && hasMetaDisplay && 'pt-[35px]',
      cardClassName,
    )}>
      <div className='absolute z-[-1] inset-0 bg-primary/10' />

      {productData.meta?.bestSeller && (<ProductCardFlare type={product.metaFlareStyle} text={
        product.metaFlareStyle === 'ribbons' ? 'Best Seller' : 'Our best selling product!'
      } />)}
      {!productData.meta?.bestSeller && productData.meta?.bestValue && (<ProductCardFlare type={product.metaFlareStyle} text={
        product.metaFlareStyle === 'ribbons' ? 'Best Value' : 'Best value for your money!'
      } />)}
      {!productData.meta?.bestSeller && !productData.meta?.bestValue && productData.meta?.new && (<ProductCardFlare type={product.metaFlareStyle} className='bg-secondary' text={
        product.metaFlareStyle === 'ribbons' ? 'New Product' : 'This product just launched!'
      } />)}

      {productImage && <ProductCardImage product={product} tabIndex={-1} />}

      <CardHeader className={cn(
        'flex grow flex-col text-center items-center justify-center',
        productImage && 'border-t',
      )}>
        <CardTitle className='font-bold'>{productData.name}</CardTitle>
        <div className='font-bold text-xl text-green-500 dark:text-green-400'>
          {displayProductPrice}
        </div>
        <CardDescription dangerouslySetInnerHTML={{ __html: productData.description }} />
      </CardHeader>
      <CardContent className={cn(
      )}>
        <div className={cn(
          'flex flex-row rounded',
          'bg-gradient-to-r from-primary/10 from-10% via-primary/15 to-primary/10 to-90%',
          'dark:bg-gradient-to-r dark:from-primary/25 dark:from-10% dark:via-primary/75 dark:to-primary/25 dark:to-90%',
        )}>
          {hasInfoDisplay && (
            <HybridTooltipProvider delayDuration={0}>
              <HybridTooltip>
                <HybridTooltipTrigger asChild>
                  <Button
                    variant='ghost'
                    className={cn(
                      'mgsm-product-info-tooltip-button',
                      storeButtonClassName,
                      'w-fit rounded-r-none border-r-0',
                    )}
                  >
                    <HelpCircleIcon size={24} />
                  </Button>
                </HybridTooltipTrigger>
                <HybridTooltipContent className="w-full max-w-80">
                  <ProductTooltipDisplay product={product} siteConfiguration={siteConfiguration} />
                </HybridTooltipContent>
              </HybridTooltip>
            </HybridTooltipProvider>
          )}
          <LinkButton
            href={productPageURL}
            variant='ghost'
            className={(cn(
              'mgsm-product-purchase-button',
              storeButtonClassName,
              'w-full',
              hasInfoDisplay && 'rounded-l-none',
            ))}
            {...linkButtonProps}
          >
            <div className={cn(hasInfoDisplay && '-ml-8')}>
              {actionText}
            </div>
          </LinkButton>
        </div>
      </CardContent>
    </Card>
  </motion.div>);
};

export default ProductCard;
