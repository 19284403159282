import { CheckIcon, LucideIcon } from 'lucide-react';
import React, { ReactNode } from 'react';
import { Alert, AlertDescription, AlertTitle } from '../ui/alert';
import { cn } from '@/lib/utils';

const SuccessAlert = ({
  title,
  description,
  Icon = CheckIcon,
  className,
}: {
  title: string;
  description?: string | ReactNode;
  Icon?: LucideIcon;
  className?: string;
}) => {
  return (
    <Alert className={cn('mgsm-success-alert bg-gray-100 dark:bg-gray-900', className)}>
      <Icon className="h-4 w-4 text-green-600" />
      <AlertTitle className='text-green-600'>{title}</AlertTitle>
      {description && <AlertDescription className='text-gray-900 dark:text-gray-400'>
        {description}
      </AlertDescription>}
    </Alert>
  );
};

export default SuccessAlert;
