import React from 'react';

import styles from './loader.module.css';
import { cn } from '@/lib/utils';

const Loader = ({
  text,
  className,
}: {
  text?: string
  className?: string
}) => {
  return (<div>
    <div className={cn(
      'mgsm-loader',
      styles.loader,
      className,
    )}>
      {Array.from({ length: 15 }).map((_, i) => <span key={i} />)}
    </div>
    {text && <div className='text-center mt-[calc(125px)]'>{text}</div>}
  </div>);
};

export default Loader;
