import React from 'react';

import { InfoIcon } from 'lucide-react';
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from '@/components/ui/avatar';
import { PopulatedProduct, PopulatedProductVariant, SiteConfiguration } from '@repo/api-types/generated';
import { cmsImageUrl } from '@/lib/utils';

const ProductTooltipDisplay = ({ product, siteConfiguration }: {
  product: PopulatedProduct | PopulatedProductVariant;
  siteConfiguration: SiteConfiguration;
}) => {
  return (<div className="mgsm-product-tooltip flex justify-between space-x-4">
    <Avatar className='grow-0'>
      {siteConfiguration.attributes.favicon?.data && <AvatarImage src={cmsImageUrl(siteConfiguration.attributes.favicon.data.attributes.url)} />}
      <AvatarFallback>
        {product.name.split(' ').map((e) => e[0]).join('').substring(0, 2)}
      </AvatarFallback>
    </Avatar>
    <div className="space-y-1">
      <h4 className="text-base font-semibold">
        {product.name}
      </h4>
      {product.benefitsDisplay?.length ? (
        typeof product.benefitsDisplay === 'string'
          ? <p className="text-sm">{product.benefitsDisplay}</p>
          : Array.isArray(product.benefitsDisplay) && (<div>
            <h5 className="sr-only text-sm font-semibold pb-1">
              This product includes:
            </h5>
            <ul className="list-disc list-inside text-xs">
              {product.benefitsDisplay.map((e, i) => (
                <li key={i}>{e}</li>
              ))}
            </ul>
          </div>)
      ) : null}

      {/* Show multiple tooltip FYI entries */}
      {Array.isArray(product.tooltipDisplay) && product.tooltipDisplay?.length ? (
        <div>
          <h5 className="sr-only text-sm font-semibold pb-1">
            Some things you should know:
          </h5>
          <ul className="list-disc list-inside text-xs">
            {product.tooltipDisplay.map((e, i) => (
              <li key={i}>{e}</li>
            ))}
          </ul>
        </div> 
      ) : null}

      {/* Show single tooltip when no other content in card */}
      {!product.benefitsDisplay?.length && product.tooltipDisplay?.length && !Array.isArray(product.tooltipDisplay) ? (
        <p className="text-xs text-muted-foreground">
          {product.tooltipDisplay}
        </p>
      ) : null}
      
      {/* Show single tooltip footer with other content in card */}
      {product.benefitsDisplay?.length && product.tooltipDisplay?.length && !Array.isArray(product.tooltipDisplay) ? (
        <div className="flex items-center pt-2">
          <InfoIcon className="mr-2 h-4 w-4 opacity-70" />{' '}
          <span className="text-xs text-muted-foreground">
            {product.tooltipDisplay}
          </span>
        </div>
      ) : null}
    </div>
  </div>);
};

export default ProductTooltipDisplay;
